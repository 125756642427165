// Sass Mixins
// ---------------------------------------- [START] Flex
@mixin fc($justify, $align) {
	display: flex;

	@if $justify {
		justify-content: $justify;
	}

	@if $align {
		align-items: $align;
	}
}

// ---------------------------------------- [END] Flex

// ---------------------------------------- [START] BEM Mixin
// 遵循BEM的規則，於class增加tag（僅限於單層方式，多層不適用）
// 可使用（單層）： .component__link => a.component__link
// 不適用（多層）： .component .component__link => .component a.component__link
@mixin bem-tag($tag: 'a') {
	$current: &;

	@at-root #{$tag}#{$current} {
		@content;
	}
}

// ---------------------------------------- [END] BEM Mixin

// ---------------------------------------- [START] before-img-size
// 使用 ::before 的 padding-top 撐高度
@mixin before-img-size($width: 1, $height: 1, $build-other: true) {
	@if $build-other {
		content: '';
		display: block;
	}

	padding-top: percentage($height / $width);
}

// ---------------------------------------- [END] before-img-size

// ---------------------------------------- [START] Media Query 相關
// Hover Media Query (使用 Foundation Mixin)
$project-has-ie: false; // 專案是否有含 IE => 有 IE 就不能使用 @media hover

@mixin breakpoint-hover($hover-enable: true) {

	// 有 IE 專案
	@if $project-has-ie {
		@if $hover-enable {
			@include breakpoint(xlarge) {
				@content;
			}
		}

		@else {
			@include breakpoint(xlarge down) {
				@content;
			}
		}
	}

	// 無 IE 專案
	@else {
		@if $hover-enable {
			@media (hover: hover) {
				@content;
			}
		}

		@else {
			@media (hover: none) {
				@content;
			}
		}
	}
}

// Breakpoint Between (使用 Foundation Mixin)
@mixin breakpoint-between($start, $end) {
	@media screen and #{breakpoint($start)} and #{breakpoint($end down)} {
		@content;
	}
}

// ---------------------------------------- [END] Media Query 相關

// ---------------------------------------- [START] Custom Scroll Bar
$scrollbar-thumb-color: $primary-color; // 操作顏色
$scrollbar-track-color: #eee; // 滾軸背景
$scrollbar-width: 6px; // 寬度(瀏覽器預設為15px)
$scrollbar-border-radius: 6px; // 圓角，Firefox不支援

@mixin custom-scroll-bar($thumb-color: $scrollbar-thumb-color, $track-color: $scrollbar-track-color) {

	// For Chrome, Opera, Safari, Android, iOS
	&::-webkit-scrollbar {
		width: $scrollbar-width;
		height: $scrollbar-width;
	}

	&::-webkit-scrollbar-track {
		background: $track-color;
	}

	&::-webkit-scrollbar-thumb {
		background: $thumb-color;
		border-radius: $scrollbar-border-radius;

		&:hover {
			background: darken($thumb-color, 10%);
		}
	}

	@if & {
		// For Firefox (版本必須64+)
		// 如果是整頁<body>要使用，必須將Class增加於<html>標籤上
		// Ref: https://stackoverflow.com/a/6165489/11240898
		// 顏色：操作顏色(Thumb) 滾軸背景(Track)
		scrollbar-color: $thumb-color $track-color;
		// 寬度只有 auto | thin | none 選項
		// auto為15px，thin為6px，none為不顯示
		scrollbar-width: thin;
	}
}

// ---------------------------------------- [END] Custom Scroll Bar

// ---------------------------------------- [START] Placeholder
@mixin optional-at-root($sel) {
	@at-root #{if(not &, $sel, selector-append(&, $sel))} {
		@content;
	}
}

// Ref: https://stackoverflow.com/a/17181946
@mixin placeholder($color: #ccc) {
	@include optional-at-root('::-webkit-input-placeholder') {
		color: $color;
	}

	@include optional-at-root(':-moz-placeholder') {
		color: $color;
	}

	@include optional-at-root('::-moz-placeholder') {
		color: $color;
	}

	@include optional-at-root(':-ms-input-placeholder') {
		color: $color;
	}
}

// ---------------------------------------- [END] Placeholder

// ---------------------------------------- [START] <select>
// Select Arrow
// 轉換SVG To Base64(data image): https://www.zhangxinxu.com/sp/svgo/
@function select-arrow-builder($color: $body-font-color) {
	$color-normal: str-replace('' + $color, '#', '');
	$build-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='%23#{$color-normal}' d='M25.1 29.65a6.91 6.91 0 0 1-1.7 0 7.06 7.06 0 0 1-3.47-1.75c-1.4-1.13-3-2.78-5.33-5.09l-5.68-5.73a1.3 1.3 0 0 0-1.84 1.84l5.73 5.78a74 74 0 0 0 5.49 5.23 9.4 9.4 0 0 0 4.77 2.29 8.48 8.48 0 0 0 2.36 0 9.4 9.4 0 0 0 4.77-2.29 74 74 0 0 0 5.49-5.23l5.73-5.78a1.3 1.3 0 0 0-1.84-1.84l-5.68 5.73c-2.29 2.31-3.93 4-5.33 5.09a7.06 7.06 0 0 1-3.47 1.75z' fill-rule='evenodd'/%3E%3C/svg%3E";
	@return $build-svg;
}

@function select-arrow-reverse-builder($color: $body-font-color) {
	$color-normal: str-replace('' + $color, '#', '');
	$build-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='%23#{$color-normal}' d='M23.4 19.35a6.91 6.91 0 0 1 1.7 0 7.06 7.06 0 0 1 3.47 1.75c1.4 1.13 3 2.78 5.33 5.09l5.68 5.73a1.3 1.3 0 0 0 1.84-1.84l-5.73-5.78a74 74 0 0 0-5.49-5.23 9.4 9.4 0 0 0-4.77-2.29 8.48 8.48 0 0 0-2.36 0 9.4 9.4 0 0 0-4.77 2.29 74 74 0 0 0-5.49 5.23l-5.73 5.78a1.3 1.3 0 0 0 1.84 1.84l5.68-5.73c2.29-2.31 3.93-4 5.33-5.09a7.06 7.06 0 0 1 3.47-1.75z' fill-rule='evenodd'/%3E%3C/svg%3E";
	@return $build-svg;
}

$select-arrow: select-arrow-builder();
$select-arrow-white: select-arrow-builder($white);
$select-arrow-reverse: select-arrow-reverse-builder();
$select-arrow-reverse-white: select-arrow-reverse-builder($white);

// Select Style
@mixin select-style($use-white: false) {
	padding-right: rem-calc(40);
	text-overflow: ellipsis;
	cursor: pointer;

	// Arrow
	background: no-repeat right 12px center / 15px;

	@if $use-white {
		background-image: url('#{$select-arrow-white}');
	}

	@else {
		background-image: url('#{$select-arrow}');
	}

	@include breakpoint(medium) {
		padding-right: rem-calc(55);
		background-position: right 19px center;
		background-size: 18px;
	}
}

// ---------------------------------------- [END] <select>

// ---------------------------------------- [START] 文字溢行
@mixin clamp($line-limit: 1, $line-height: 1.6) {
	position: relative;
	display: block;
	display: -webkit-box;
	overflow: hidden;
	max-width: 100%;
	line-height: 1.6;
	line-height: var(--line-height, $line-height);
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: var(--line-limit, $line-limit);
}

// ---------------------------------------- [END] 文字溢行

// ---------------------------------------- [START] Toggle Show Style
// Toggle Show Style
// https://stackoverflow.com/a/30531678
$d-opacity: 0.25s;
$d-disappear: 0.5s;

/// @param {string} $switch - 開關class
/// @param {string} $targetEl - 要縮起展開的物件（通常為<a> ）
// 放在要加的 "is-open"(開關Class) class 的物件上
// @include toggle-menu-item('開關Class', '內層展開收闔物件，通常為<a>')
@mixin toggle-menu-item--disable {
	margin: 0;
	padding-top: 0;
	padding-bottom: 0;
	font-size: 0;
	line-height: 0;
	color: transparent;
	border-width: 0;
	-webkit-speak: none;
	opacity: 0;
	transition: opacity $d-opacity,
		font-size $d-disappear $d-opacity,
		line-height $d-disappear $d-opacity,
		margin $d-disappear $d-opacity,
		padding $d-disappear $d-opacity,
		color $d-opacity;
}

@mixin toggle-menu-item--enabled {
	transition: font-size $d-opacity,
		margin $d-opacity,
		padding $d-opacity,
		line-height $d-opacity,
		color $d-opacity,
		opacity $d-disappear $d-opacity;
}

@mixin toggle-menu-item($switch, $targetEl, $extendPlaceholderSelector) {

	// 使用 @extend 統整樣式
	// 因為要用此方法，必須先在同一個 @media 底下建立替代 Selector，範例：
	// ※命名結束一定要為「--disable」、「--enabled」，前面文字要相同
	// @at-root %toggle-menu-common--disable {
	// 	@include toggle-menu-item--disable;
	// }
	// @at-root %toggle-menu-common--enabled {
	// 	@include toggle-menu-item--enabled;
	// }
	@if $extendPlaceholderSelector {

		// Hide
		&:not(#{$switch}) {
			#{$targetEl} {
				@extend #{$extendPlaceholderSelector}--disable;
			}
		}

		// Show
		&#{$switch} {
			#{$targetEl} {
				@extend #{$extendPlaceholderSelector}--enabled;
			}
		}
	}

	@else {

		// Hide
		&:not(#{$switch}) {
			#{$targetEl} {
				@include toggle-menu-item--disable;
			}
		}

		// Show
		&#{$switch} {
			#{$targetEl} {
				@include toggle-menu-item--enabled;
			}
		}
	}

}

// ---------------------------------------- [END] Toggle Show Style

// ---------------------------------------- [START] Animation Set
@mixin set-animate($animateEl) {
	@each $class in $animateEl {
		$i: index($animateEl, $class);
		$i: $i;

		#{$class} {
			opacity: 0;
			transform: translateY(50px);
			transition-duration: 1s;
			transition-property: opacity, transform;
			transition-delay: #{$i * 0.3s};
		}
	}
}

@mixin set-animate-default($animateEl) {
	@each $class in $animateEl {
		$i: index($animateEl, $class);
		$i: $i;

		#{$class} {
			transition-duration: 1s;
			transition-property: opacity, transform;
			transition-delay: #{$i * 0.3s};
		}
	}
}

@mixin set-animate-from($animateEl) {
	@each $class in $animateEl {
		$i: index($animateEl, $class);
		$i: $i;

		#{$class} {
			opacity: 0;
			transform: translateY(50px);
		}
	}
}

@mixin set-animated($animateEl) {
	@each $class in $animateEl {
		#{$class} {
			opacity: 1;
			transform: none;
		}
	}
}

@mixin set-animate-flow($animateEl) {
	&.js-ani {
		@include set-animate($animateEl);

		&.is-animated {
			@include set-animated($animateEl);
		}
	}
}

@mixin set-animate-flow--not($animateEl) {
	&.js-ani {
		@include set-animate-default($animateEl);

		&:not(.is-animated) {
			@include set-animate-from($animateEl);
		}
	}
}


// ---------------------------------------- [END] Animation Set
