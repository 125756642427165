@mixin font-icon {
	@font-face {
		font-family: "icon";
		src: url('../fonts/icons/icon.eot');
		src: url('../fonts/icons/icon.eot?#iefix') format('embedded-opentype'),
		url('../fonts/icons/icon.woff2') format('woff2'),
		url('../fonts/icons/icon.woff') format('woff'),
		url('../fonts/icons/icon.ttf') format('truetype'),
		url('../fonts/icons/icon.svg#icon') format('svg');
		font-display: block;
		unicode-range: U+EA01-EA21;
	}

	.be-icon {
		font-family: sans-serif;
		line-height: 1;
	}

	.be-icon:before {
		@include font-icon-style;
	}
	
	/* Glyphs list */
	
	.be-icon-arrow-down:before {
		content: '\ea01';
	}
	
	.be-icon-arrow-left:before {
		content: '\ea02';
	}
	
	.be-icon-arrow-right:before {
		content: '\ea03';
	}
	
	.be-icon-arrow-up:before {
		content: '\ea04';
	}
	
	.be-icon-award:before {
		content: '\ea05';
	}
	
	.be-icon-calculate:before {
		content: '\ea06';
	}
	
	.be-icon-call:before {
		content: '\ea07';
	}
	
	.be-icon-click-top-2_circle:before {
		content: '\ea08';
	}
	
	.be-icon-click-top-2_hand:before {
		content: '\ea09';
	}
	
	.be-icon-click-top:before {
		content: '\ea0a';
	}
	
	.be-icon-close:before {
		content: '\ea0b';
	}
	
	.be-icon-fb-msg:before {
		content: '\ea0c';
	}
	
	.be-icon-fb:before {
		content: '\ea0d';
	}
	
	.be-icon-global:before {
		content: '\ea0e';
	}
	
	.be-icon-heart-light:before {
		content: '\ea0f';
	}
	
	.be-icon-heart-solid:before {
		content: '\ea10';
	}
	
	.be-icon-heart:before {
		content: '\ea11';
	}
	
	.be-icon-ig:before {
		content: '\ea12';
	}
	
	.be-icon-line:before {
		content: '\ea13';
	}
	
	.be-icon-logout:before {
		content: '\ea14';
	}
	
	.be-icon-member-data:before {
		content: '\ea15';
	}
	
	.be-icon-money:before {
		content: '\ea16';
	}
	
	.be-icon-patner:before {
		content: '\ea17';
	}
	
	.be-icon-phone:before {
		content: '\ea18';
	}
	
	.be-icon-plus:before {
		content: '\ea19';
	}
	
	.be-icon-process:before {
		content: '\ea1a';
	}
	
	.be-icon-profile:before {
		content: '\ea1b';
	}
	
	.be-icon-quotation:before {
		content: '\ea1c';
	}
	
	.be-icon-search:before {
		content: '\ea1d';
	}
	
	.be-icon-standard:before {
		content: '\ea1e';
	}
	
	.be-icon-storage:before {
		content: '\ea1f';
	}
	
	.be-icon-style:before {
		content: '\ea20';
	}
	
	.be-icon-yt:before {
		content: '\ea21';
	}
	
}

// <i>:before Style
@mixin font-icon-style {
	font-family: "icon";
	
	// Font smoothing. That was taken from TWBS
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-style: normal;
	font-weight: normal;

	// For safety - reset parent styles, that can break glyph codes
	font-variant: normal;
	text-transform: none;
	text-decoration: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	// only necessary if not using the private unicode range (firstGlyph option)
	speak: none;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;

	// fix buttons height, for twitter bootstrap
	line-height: 1em;

	// Animation center compensation - margins should be symmetric
	// remove if not needed
	margin-left: .2em;

	// you can be more comfortable with increased icons size
	// font-size: 120%;

	// Uncomment for 3D effect
	// text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}

// @include font-icon;

// SASS變數使用範例
// .icon {
//     &:before{
// 		@include font-icon-add(SVG檔案名稱);
//     }
// }

// 內頁使用之Style
%font-icon-style {
	@include font-icon-style;
}


$font-icon-arrow-down: '\ea01';

$font-icon-arrow-left: '\ea02';

$font-icon-arrow-right: '\ea03';

$font-icon-arrow-up: '\ea04';

$font-icon-award: '\ea05';

$font-icon-calculate: '\ea06';

$font-icon-call: '\ea07';

$font-icon-click-top-2_circle: '\ea08';

$font-icon-click-top-2_hand: '\ea09';

$font-icon-click-top: '\ea0a';

$font-icon-close: '\ea0b';

$font-icon-fb-msg: '\ea0c';

$font-icon-fb: '\ea0d';

$font-icon-global: '\ea0e';

$font-icon-heart-light: '\ea0f';

$font-icon-heart-solid: '\ea10';

$font-icon-heart: '\ea11';

$font-icon-ig: '\ea12';

$font-icon-line: '\ea13';

$font-icon-logout: '\ea14';

$font-icon-member-data: '\ea15';

$font-icon-money: '\ea16';

$font-icon-patner: '\ea17';

$font-icon-phone: '\ea18';

$font-icon-plus: '\ea19';

$font-icon-process: '\ea1a';

$font-icon-profile: '\ea1b';

$font-icon-quotation: '\ea1c';

$font-icon-search: '\ea1d';

$font-icon-standard: '\ea1e';

$font-icon-storage: '\ea1f';

$font-icon-style: '\ea20';

$font-icon-yt: '\ea21';


// 可以帶入變數
@mixin font-icon-add($icon: '', $extendStyle: true) {
	
	@if $icon == arrow-down {
		content: '\ea01';
	}
	
	@if $icon == arrow-left {
		content: '\ea02';
	}
	
	@if $icon == arrow-right {
		content: '\ea03';
	}
	
	@if $icon == arrow-up {
		content: '\ea04';
	}
	
	@if $icon == award {
		content: '\ea05';
	}
	
	@if $icon == calculate {
		content: '\ea06';
	}
	
	@if $icon == call {
		content: '\ea07';
	}
	
	@if $icon == click-top-2_circle {
		content: '\ea08';
	}
	
	@if $icon == click-top-2_hand {
		content: '\ea09';
	}
	
	@if $icon == click-top {
		content: '\ea0a';
	}
	
	@if $icon == close {
		content: '\ea0b';
	}
	
	@if $icon == fb-msg {
		content: '\ea0c';
	}
	
	@if $icon == fb {
		content: '\ea0d';
	}
	
	@if $icon == global {
		content: '\ea0e';
	}
	
	@if $icon == heart-light {
		content: '\ea0f';
	}
	
	@if $icon == heart-solid {
		content: '\ea10';
	}
	
	@if $icon == heart {
		content: '\ea11';
	}
	
	@if $icon == ig {
		content: '\ea12';
	}
	
	@if $icon == line {
		content: '\ea13';
	}
	
	@if $icon == logout {
		content: '\ea14';
	}
	
	@if $icon == member-data {
		content: '\ea15';
	}
	
	@if $icon == money {
		content: '\ea16';
	}
	
	@if $icon == patner {
		content: '\ea17';
	}
	
	@if $icon == phone {
		content: '\ea18';
	}
	
	@if $icon == plus {
		content: '\ea19';
	}
	
	@if $icon == process {
		content: '\ea1a';
	}
	
	@if $icon == profile {
		content: '\ea1b';
	}
	
	@if $icon == quotation {
		content: '\ea1c';
	}
	
	@if $icon == search {
		content: '\ea1d';
	}
	
	@if $icon == standard {
		content: '\ea1e';
	}
	
	@if $icon == storage {
		content: '\ea1f';
	}
	
	@if $icon == style {
		content: '\ea20';
	}
	
	@if $icon == yt {
		content: '\ea21';
	}
	

	@if $extendStyle == true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}